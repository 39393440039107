import styled from 'styled-components'

const StyledContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
`

const custom404Page = () => (
    <StyledContainer>404 - Page Not Found</StyledContainer>
)

export default custom404Page
